$input-height: 44px;

html, body, .screen, main {
	height: 100%;
}
body {
	font-family: "Lato", sans-serif;
	margin: 0;
	text-rendering: optimizeLegibility;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

*, ::after, ::before {
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}

.screen {
	min-width: 300px;
	min-height: 100vh;
	display: flex;
	justify-content: center;
	width: 100%;
}

aside {
	width: 400px;
	min-height: 100%;
	background: $main-menu-background-color;
	border-top: 3px solid $page-border-top-background;
	margin: 0;
	padding: 40px 30px 0 30px;
	position: relative;
	max-height: 100vh;
}

aside .aside-logo{
	width: 235px;
	height: 150px;
	background-image:url($main-menu-logo-path);
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	display: block;
	margin: 0 auto 40px auto;
}

aside .not-registered{
	margin: 0px 0px 40px 0px;
}

aside.login section:not(:nth-of-type(1)), 
aside.sso section:not(:nth-of-type(1)),
aside.signin section:not(:nth-of-type(1)) {
	display: none;
}
aside.signup section:not(:nth-of-type(2)) {
	display: none;
}
aside.reset-password section:not(:nth-of-type(3)) {
	display: none;
}
aside.resend-confirm-email section:not(:nth-of-type(4)) {
	display: none;
}

.loginform.invisible {
	display: none;
}

.eula {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 999;
    background-color: #fff;
    font-size: 18px;
    padding-left: 20%;
    padding-right: 20%;
    
    &.invisible {
    	display: none;
    }

    > .container {
    	margin-right: auto;
    	margin-left: auto;
    	text-align: center;

	    > iframe {
	    	min-width: 600px;
	    	max-width: 750px;
	    	min-height: 800px;
	    }

	    > .buttons {
	    	color: #333;
	    }
	}

}

header {
	text-align: center;
	margin: 42px auto 1rem;
}

main {
	min-height: 630px;
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background-color: rgba(240, 240, 240,.8);
}

img[src$="illustration.png"] {
	width: 650px;
	max-width: 80%;
	margin: 3em 0;
}

.card-footer {
	font-size: 13px;
	text-align: center;
	padding-bottom: 20px;
	padding-top: 27px;
	position: relative;
	z-index: 2;
}

input[type=email], input[type=text], input[type=password] {
	outline: none;
	display: block;
	border: none;
	border-bottom: 1px solid $inputs-border-bottom-background;
	line-height: 50px;
	height: $input-height;
	width: 100%;
	max-width: 100%;
	font-size: 18px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	color: $inputs-font-color;
	background-position: right 5px center;
	padding: 0 6px;
	letter-spacing: 1px;
	background-color: $inputs-background;
}
input[type=submit] {
	text-transform: uppercase;
}
input[type="password"] {
	letter-spacing: 6px;
}
/* input[type=checkbox] {
	vertical-align: middle;
	margin-left: 10px;
} */

a[href]:not(.btn) {
	color: $links-background;
	text-decoration: none;
}
a[href]:not(.btn):hover {
	color: #263139;
	text-decoration: none;
}
.sep {
	text-align: center;
}

label {
	flex: 1;
	cursor: pointer;	
	font-size: 13px;
	color: rgba(0,0,0,.85);
}

.form-group{
	position: relative;

	.password-group {
		display: flex;
		height: $input-height;

		.password-input {
			width:90%;
		}
		.display-password {
			display: flex;
			width: 10%;
			position: relative;
			justify-content: center;
			align-items: center;
			color: white;
			border-bottom: 1px solid $inputs-border-bottom-background;
			background-color: $inputs-background;

			label {
				display: flex;
				justify-content: center;
				align-items: center;

				input {
					position: absolute;
					display: none;
				}

				svg.eye {
					opacity: 0.2;
					display: none;

					path.eye {
						fill: $inputs-font-color;
					}
				}

				input + div.eye {
					svg.eye:not(.hidden) {
						display: none;
					}
					svg.eye.hidden {
						display: unset;
					}
				}
				input:checked + div.eye {
					svg.eye:not(.visible) {
						display: none;
					}
					svg.eye.visible {
						display: unset;
					}
				}
			}
		}
	}
}

.form-group a.forgot-pwd{
	position: absolute;
	right: 0;
	top: 45px;
	font-family: 'Lato', sans-serif;
	font-weight: 400;
	font-size: 15px;
	color: $forgot-password-link-color;
	width: 140px;
	display: inline-block;
	text-align: right;

}

.btn {
	color: #eee;
	outline: none;
	font-size: 20px;
	font-family: 'Lato', sans-serif;
	font-weight: $button-font-weight;
	background: #4d90fe;
	border: none;
	padding: 5px 12px;
	text-transform: capitalize;
	align-items: center;
	text-align: center;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
	height: 40px;
	box-shadow: 0px 2px 4px rgba(0,0,0,0.25)
}
.form-group .btn span {
	font-size: 18px;
	font-family: 'Lato', sans-serif;
	font-weight: 600;
}
.btn + .btn {
	margin-left: 15px;
}
.btn[type=submit] {
	background: $login-background-button;
	font-size: 18px;
	font-family: 'Lato', sans-serif;
	font-weight: 600;
}

.btn.google {
	display: flex;
	background: $google-background-button;
	height: 42px;
	margin-bottom: 0;
	font-size: 18px;
	font-weight: 600;
}
.btn.facebook {
	display: flex;
	background: $facebook-background-button;
	height: 42px;
	border-radius: 4px;
	cursor: pointer;
}
.btn.facebook > .txt {
	font-size: 16px;
	font-weight: 400;
	font-family: Helvetica, Arial, sans-serif;
	letter-spacing: .25px;
    text-overflow: clip;
    white-space: nowrap;
}

.btn.msoffice {
	height: 41px !important;
	display: flex;
	background: $microsoft-background-button;
	height: 42px;
	margin-bottom: 0;
	cursor: pointer;
	color: #5e5e5e;
	border-width: 1px;
	border-style: solid;
	border-color: #8c8c8c;
}

.btn.msoffice > .txt {
	font-size: 15px;
	font-family: 'Segoe UI Regular', 'Lato', sans-serif;
	font-weight: 600;
}

.btn.account{
	display: flex;
	background: $account-background-button;
	height: 42px;
	font-size: 18px;
	font-weight: 600;
}

a.btn {
	text-decoration: none;
	background-color: transparent;
}








.logo {
/*	background-image: url(/static/logo.svg);
	background-size: 80px 80px;
	background-repeat: no-repeat;*/
	height: 48px;
	width: 174px;
	object-fit: contain;
}

img, svg {
	max-width: 100%;
	max-height: 100%;
	object-fit: contain;
}

.btn:hover {
	filter: brightness(.9);
}


.card {
	background-color: $card-background;
	position: relative;
	width: 700px;
	max-width: 100%;
	border: 1px solid #DBDBDB;
	/*background-color: #f7f7f7;*/
	/*box-shadow: 0 2px 2px 0 rgba(0,0,0,0.14), 0 3px 1px -2px rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.2);*/
	z-index: 2;
	margin: 0 auto;
	display: flex;
	padding: 10px 40px 15px;
	flex-direction: column;
	/*overflow: hidden;*/
	/*border-top: 4px solid #e30061*/
	margin-top: 70px;
	border-radius: 10px;
	
	h3 {
		margin-top: 0px;
	}
	
	.small-label {
		color:rgba(0,0,0,0.3);
		font-size:9px;
	}
	
	.change {
		.user-avatar {
			object-fit: cover;
			display:flex;
		}
	}
	
	.camera {
		color: white;
		background: $change-user-logo-camera-background;
		position: absolute;
		border-radius: 24px;
		height: 31px;
		width: 31px;
		display: flex;
		justify-content: center;
		align-items: center;
		right: 5px;
		bottom: 0px;
		
		.camera-icon {
			height: 13px;
			width: 13px;
			background-size: 100%;
			background-image: url("/static/img/camera.svg");
			background-repeat: no-repeat;
			background-position: center;
		}
	}

	.change-logo {
		.small-label {
			justify-content: center;
    		display: flex;
		}
	}
}

.card-small {
	width: 452.72px;
	height: 278.42px;
	padding: 0px 40px 15px;
	
	figure img, figure svg {
		height: 126px;
		width: 126px;
	}
	
	figcaption {
		span.name {
			font-size: 24px;
		}
		span.email {
			font-size: 17px;
		}
	}
}

.card::before {
	content: '';
	background-image: url($card-image-path);
	height: 40px;
	min-height: 40px;
	object-fit: contain;
	display: block;
	transform: $card-before-transform;
	display: flex;
	align-items: center;
	justify-content: center;
	background-size: 280px;
	background-repeat: no-repeat;
	background-position: center;
}

.card figure {
	width:150px;
	margin: 0 auto;
	display: flex;
	flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}
.card .navlinks {
	display: flex;
	justify-content: space-between;
}

.row {
	display: flex;
	align-items: center;
	margin-top: 20px;
}

h1 {
	font-size: 46px;
	margin-top: 1rem;
}
h1, h2, h3 {
	font-weight: 300;
	text-align: center;
	color: #555
}
h2 {
	text-transform: smallcaps;
	color: purple;
	font-weight: 350;
}
.screen main p {
	font-size: 1.2em;
	margin: 1rem 0;
}


.form-group {
	margin-bottom: 25px;
}
.form-group span {
	font-size: 13px;
	flex: 1;
}
.form-group > :only-child:not(input):not(.password-group) {
	flex:1;
	text-align: center;
	height: 40px;
}
.form-group.row {
	justify-content: space-between;
}
label.form-group {
	display: flex;
	flex-direction: column;
	position: relative;
}
label.form-group > input {
	order: 2;
}
form[action="/signup"] .form-group.row {
	margin-top: 40px;
}

.agreement{
	margin-bottom: 15px;
	text-align: center;
}

.agreement span{
	font-size: 14px;
	color: #777;
	font-weight: bold;
}

.agreement input{
	padding: 0;
	margin-left: 0;
	margin-right: 6px;
	margin-bottom: 4px;
}

.remember-me input{
	padding: 0;
	margin-left: 0;
	margin-right: 6px;
	margin-bottom: 7px;
}

.remember-me label{
	font-size: 18px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	color: #2C2C2C;
}

::-webkit-input-placeholder {
	font-size: 18px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	transition: color .3s;
	letter-spacing: 1px;
	color: #888;
}
::-moz-placeholder {
	font-size: 18px;
	font-family: 'Lato', sans-serif;
	font-weight: 300;
	transition: color .3s;
	letter-spacing: 1px;
	color: #888;
}

.already-registered{
	text-align: center;
	font-size: 16px;
}


figure {
	padding: 0;
	text-align: center;
}
figure .change {
	position: relative;
	border-radius: 50%;
	display: inline-block;
	border: 4px solid #F2F2F2;
	overflow: hidden;
}
figure img, figure svg {
	height: 142px;
	width: 142px;
	object-fit: contain;
}
figure .change span {
	font-weight: bold;
	color: white;
	font-size: 16px;
	padding: 4px 5px 9px;
	background: rgba(0,0,0,.75);
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
}



footer a {
	color: #7C7CFF;;
	display: inline-block;
	white-space: nowrap;
	text-overflow: ellipsis;
}

footer {
	font-size: 13px;
	padding: 15px 30px;
	text-align: right;
	overflow-x: hidden;
	position: absolute;
	bottom: 0;
	width: calc(100% - 60px);
}



.wrap {
	max-width: 1100px;
	margin: 0 auto;
}
.g-recaptcha {
	visibility: hidden;
	/*margin: 0 auto 10px;*/
}

article {
	padding: 1em 1em 0;
}


.menu:not(.active) > :last-child {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}
.menu {
	position: relative;
	margin: 10px 0 0;
	padding: 0;
}
.menu > :first-child {
	cursor: default;
}
.menu > :last-child {
	position: absolute;
	box-shadow: 0 0 4px 1px #999;
	padding: 12px 0;
	top: 120%;
	left: calc(50% - 54px);
	background: #fafafa;
	opacity: 1;
	visibility: visible;
	pointer-events: auto;
	z-index: 9;
	transition: all .2s;
	/*transition-delay: .1s;*/
	border-radius: 5px;
  width: max-content;
}

.menu > :last-child::before {
	content: "";
	position: absolute;
	left: 50%;
	margin-left: -8px;
	width: 16px;
	height: 16px;
	border: 8px solid transparent;
	border-bottom-color: #ccc;
	top: -16px;
}
.menu > :last-child::after {
	content: "";
	position: absolute;
	left: 50%;
	margin-left: -7px;
	width: 14px;
	height: 14px;
	border: 7px solid transparent;
	border-bottom-color: #fff;
	top: -14px;
}


.menu > :first-child::after {
	content: "▾";
	opacity: .7;
	display: inline-block;
	margin-left: 6px;
	transform: scale(.7,.7);
}
.menu li a {
	display: inline-block;
	padding: 5px 30px;
}
.menu li:hover {
  background-color: #b3b3b3;
}

ul, ol {
	list-style: none;
	padding: 0;
	margin: 0;
}

input:invalid {
	outline: none;
}
form.error input:invalid {
	outline: auto red;
}

@media (max-width: 959px) {
	main {
		display: none;
	}
	aside {
		position: relative;
		width: 100%;
		overflow: auto;
	}

	aside > .wrap {
		display: flex;
		justify-content: center;
	}
}

.welcome{
	display: none;
	min-height: 100%;
	height: 100vh;
	min-width: 100%;
}

.welcome.visible{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.welcome .navlinks{
	width: 100%;
	min-width: 150px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 0 2%;
	padding-bottom: 3%;
}

.navlinks img{
	height: 50px;
	width: 98%;
}

.welcome .navlinks a{
	height: 40px;
	margin-left: 5px;
}

.welcome .navlinks a img {
	width: auto;
	height: 40px;
	margin-top: 0;
}

.welcome.custom-theme {
	background: $custom-theme-background;
	
	.logo {
		height: 150px;
		width: 150px;
		background-image:url($custom-theme-logo-path);
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
	}
	h1.welcome-greeting-system::before{
		content: $custom-theme-greeting-system;
	}
	h2.description::before {
		content:$custom-theme-description;
	}
}

.welcome.designer{
	background-image: url("/static/img/gravitbackground.png");
	background-size: 100% 100%;
}

.welcome.klex{
	background: linear-gradient(150deg,#1565c0 15%,#00c4cc 70%,#e1e5ee 94%);
}

.welcome.old{
	background: linear-gradient(150deg, #6EA1F3 15%, #D6408E 94%);
}

.welcome.discuss{
	background: linear-gradient(45deg,#6F0064 15%, #ED1D7A 94%);
}

.welcome.normal {
	background: linear-gradient(45deg,#6F0064 15%, #ED1D7A 94%);
}


.welcome.normal .navlinks{
	width: auto;
	height: 50px;
	margin-top: 40px;
}

.welcome.normal .navlinks a{
	width: auto;
	height: 50px;
}

.welcome.normal .navlinks a img{
	width: auto;
	height: 50px;
}

.welcome .logo{
	width: 212px;
	padding-top: 5%;
	height: auto;
	box-sizing: border-box;
	min-width: 100px;
}

.welcome .hero{
	height: auto;
	min-width: 500px;
	max-height: 60vh;
}

.welcome h1{
	 color: white;
	 font-size: 28px;
	 font-weight: 400;
	 margin: 1rem 0 0 0;
	 font-family: 'Lato', sans-serif;
}

.welcome h2{
	font-family: 'Lato', sans-serif;
	color: white;
	font-size: 16px;
	font-weight: 300;
	margin: 1rem 0 2rem 0;
	max-width: 80%;
}

.form-update-profile {
	
	margin-top:26px;
	
	input[type=email], input[type=text], input[type=password] {
		background-color: $form-update-profile-inputs-background;
		color: $form-update-profile-inputs-font-color
	}

	.password-group {
		.display-password {
			background-color: $form-update-profile-inputs-background;
			label {
				svg.eye {
					path.eye {
						fill: $form-update-profile-inputs-font-color;
					}
				}
			}
		}
	}
	
	.delete-account-btn {
		display: flex;
		flex-direction: column;
		position: relative;
		color: gray;
		font-size: 20px;
		margin-top: 44px;
		box-shadow: none;
		background: none;
		border: 1px solid;
		border-radius: 7px;
	}

	.btn-update {
		background: $form-update-profile-update-button-background;
	    margin-right: 20px;
	}

	.btn-sign-out {
		background-color: $form-update-profile-btn-signout-background;
	}

	.container {
	  display: flex;  
	  flex-flow: row wrap;
	}

	.container > * {
	  padding: 10px;
	  flex: 1 100%;
	}

	.form-aside { flex: 1 50%; }
	
		
}

.home {
	background: $home-background;
	align-items: center;
}
.home p {
	text-align: center;
}
.home .name {
	font-size: 30px;
}
.home .email {
	font-size: 22px;
	color: rgba(0,0,0,.55);
}
.home .choose {
	font-size: 22px;
	text-align: center;
	margin: 20px 0 10px;
}


.home .navlinks img, .home .navlinks svg {
	max-width: none;
	max-height: none;
	margin: 20px 0 25px;
}

flash.error {
	background: rgb(173, 20, 73);
	padding: 5px 10px;
	border-radius: 2px;
	font-size: 14px;
	color: rgb(255, 255, 255);
	width: 100%;
	display: block;
	text-align: center;
}

flash.message {
	background: rgb(97, 89, 91);
	padding: 5px 10px;
	border-radius: 2px;
	font-size: 14px;
	color: rgb(255, 255, 255);
	width: 100%;
	display: block;
	text-align: center;
}

.dialog {
    display: flex;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
}

.dialog-content {
    background-color: $dialog-background-content;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    border-radius:8px;
	width: 524px;
	
	.message {
		display: flex;
		align-items: center;
		justify-content: center;
	    padding: 25px;
		color:#656466;
	}
	
	.message::before {
		content:$dialog-remove-account-message;
	}
}

.dialog-content-buttons {
	float:right;
    padding-right: 25px;
	
	.btn {
		width:96px;
	}
	
	.btn-cancel {
		background:$dialog-button-cancel-background;
	}
	
	.btn-confirm {
		background:$dialog-button-ok-background;
	}
}

.loading {
	&:after {
		content: " ";
		z-index: 10;
		display: block;
		position: absolute;
		height: 50px;
		width: 50px;
		border: 25px solid #f7f7f7;
		border-top: 25px solid $page-border-top-background;
		border-radius: 100%;
		-webkit-animation: spin 2s linear infinite;
		animation: spin 2s linear infinite;
		left: 0;
		right: 0;
		margin: auto;
		top: calc(50% - 50px);
	}

	@keyframes spin {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
}

.marketplace-header {
	background: #6f5499;
	color: white;
	padding: 30px;
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;

	> h1 {
		color: white;
		margin: 0px;
		font-weight: bold;
	}
}

.marketplace-container {
	font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
	box-sizing: border-box;
	display: flex;
	flex: 1;
	justify-content: center;
	width: 60%;
	margin: 0 auto;
	color: #333;

	> ul {
		> li {
			display: flex;
			flex-direction: row;
			margin-top: 10px;
			border-radius: 4px;
			overflow: hidden;
			padding: 10px;
			border: 1px solid #e1e1e8;

			&:hover {
				background: #f7f7f9;
			}

			> img {
				height: 300px;
				object-fit: contain;
			}

			> .info {
				display: flex;
				flex-direction: column;
				padding: 5px;

				> span {
					margin-bottom: 10px;
				}

				> .title {
					font-weight: bold;
				}

				> .description {
					flex: 1;
				}
			}
		}
	}
}

.corel-draw-app {
  background-image: url("/static/img/corel-draw-app.png");
  background-size: 24px;
  background-repeat: no-repeat;
  height: 24px;
  width: 24px;
}

/* CTRL+C CTRL+V FROM W3SCHOOLS */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .2s;
  transition: .2s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:disabled + .slider {
  opacity: 0.5;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

#update > button {
	height: 34px;
	width: 150px;

	&.update {
		background-color: #3e3;
		color: #333;
	}

	&:disabled {
		opacity: 0.5;
	}
}

.delete-button {
	float: right;
	height: 34px;
	background-color: #d33;
	color: #eee;
}

.light-hr {
  margin: 2px;
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  padding: 0;
}