@import 'variables';

$brandColor: #7A2DAD;
$baseColor1: #202326;
$baseColor2: darken($brandColor, 20%);
$baseColor3: white;

$login-background-button:$brandColor;
$main-menu-background-color: $baseColor3;
$main-menu-logo-path: "/static/img/corel-cloud-logo.png";
$page-border-top-background: $baseColor3;
$card-image-path: "/static/img/corel-cloud-logo.png";
$card-before-transform:translateY(-95px);
$form-update-profile-inputs-background: transparent;
$form-update-profile-update-button-background:$baseColor2;
$links-background: $baseColor2;
$dialog-button-ok-background: $baseColor2;
$dialog-button-cancel-background: $baseColor1;
$dialog-background-content: $baseColor3;
$change-user-logo-camera-background: $baseColor2;
$forgot-password-link-color:$baseColor2;
$form-update-profile-btn-signout-background: $baseColor1;
$card-background:white;

$home-background: radial-gradient(#727272, #B3B3B3);
$dialog-remove-account-message: "Do you really want to delete your Corel Cloud account?";

// Home screen - Login
$custom-theme-background: radial-gradient(#727272, #B3B3B3);
$custom-theme-logo-path:"/static/img/corel-cloud-logo.png";
$custom-theme-greeting-system:"Welcome to Corel Cloud";
$custom-theme-description:"A cross-platform design tool for the 21st century";

@import 'style';