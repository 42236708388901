$google-background-button: #DD4B39;
$facebook-background-button: #4267b2;
$microsoft-background-button: #ffffff;
$account-background-button: #6464FF;
$login-background-button: #CA1079;
$inputs-background: transparent;
$inputs-font-color: #2C2C2C;
$form-update-profile-inputs-font-color: #2C2C2C;
$main-menu-logo-path: "/static/img/cloudlogo.svg";
$main-menu-background-color: white;
$page-border-top-background: #CA1079;
$card-image-path: "/static/img/cloud.png";
$card-before-transform: translateY(-105px) scale(0.5);
$home-background:linear-gradient(225deg,#6F0064 15%, #ED1D7A 94%);
$form-update-profile-inputs-background: $inputs-background;
$button-font-weight:400;
$form-update-profile-update-button-background:#CA1079;
$links-background:#7C7CFF;
$dialog-button-ok-background:#CA1079;
$dialog-button-cancel-background:#656466;
$dialog-background-content:white;
$change-user-logo-camera-background:#CA1079;
$forgot-password-link-color:#6464FF;
$form-update-profile-btn-signout-background:#656466;
$card-background:white;

$inputs-border-bottom-background: #DFDFDF;
$dialog-remove-account-message: "Do you really want to delete your Gravit Cloud account?";

// Home screen - Login
$custom-theme-background:linear-gradient(225deg,#6F0064 15%, #ED1D7A 94%);
$custom-theme-logo-path:"/static/img/cloud.png";
$custom-theme-greeting-system:"Welcome to Gravit Cloud";
$custom-theme-description:"Quickly create awesome graphics and share them with the world. One account, one workspace and two awesome free tools.";